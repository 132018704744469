import React, { Component } from "react";
import data from "../data";
import SiteItem from "./SiteItem";

class SitesList extends Component {

  componentDidMount() {
    document.title = "Virtual reality porno list";
    // this.changePosition();
    // window.addEventListener('scroll', this.windowPosition);
  }

  componentWillUnmount() {
    // window.removeEventListener('scroll', this.windowPosition);
  }

  changePosition() {
    // if (commonStore.windowPosition)
    //     window.scrollTo(0, commonStore.windowPosition);
  }

  windowPosition() {
    // commonStore.changeWindowPosition(window.scrollY);
  }

  render() {
    return (
      <div className="listType">
        <main className="site">
          {data.sitesList.map((item, idx) => {
            return (
              <SiteItem
                key={idx + item.url}
                item={item}
                idx={idx}
              />
            );
          })}
        </main>
      </div>
    );
  }
}

export default SitesList;
