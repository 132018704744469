import React from "react";
import { Link } from "gatsby";

const SiteItem = props => {
  const { item, idx } = props;
  return (
    <article className="item" key={idx + item.url}>
      <h2>
        {item.name}
      </h2>
      <div className="imageWrapper">
        <Link to={`/link/${item.url}`} target='_blank'>
          <img src={item.main_image} alt={item.name}/>
          <div className="shadow"/>
        </Link>
      </div>
      <div className="linksWrapper">
        <div className="goToSite">
          <Link to={`/link/${item.url}`} target='_blank'>
            Visit Site
          </Link>
        </div>
        <div className="moreInfoLink">
          <Link to={`/site/${item.url}`}>
            More Info
          </Link>
        </div>
      </div>
    </article>
  );
};

export default SiteItem;
