import React from "react";

const Footer = () => {
  return (
    <footer>
      <p>We have reviewed and ranked the top best VR porn sites for PC, smartphones like iPhone or Android,
        Cardboard, Oculus & VR Gear.
        We have done the hard part.
        Now you can experience interactive 360° and 180° sex with your VR headset.
        It is better than anything you have experienced before!</p>
    </footer>
  );
};

export default Footer;
