const cdn = "",
  url = "",
  data = {
      tags: [
          {
              order: 0,
              name: "Straight"
          },
          {
              order: 1,
              name: "Gay"
          }
      ],
      sitesList: [
          {
              url: "badoink-vr",
              name: "BaDoink VR",
              link: "http://go.badoinkvr.com/go.php?t=22759&aid=108833&sid=06172016_300x250_BID8569",
              main_image: url + "/aff_images/badoinkvr/23.jpg",
              image: [
                  url + "/aff_images/badoinkvr/23.jpg"
              ],
              text: [
                  "100+ exclusive videos. Lesbian, MILF and more. Enjoy it with your Oculus or " +
                  "Samsung Gear VR. Free VR Goggles with every subscription."],
              tags: ["Straight", "Teen"],
              image_preview: [
                  url + "/preview_image/badoinkvr/1.jpg",
                  url + "/preview_image/badoinkvr/2.jpg",
                  url + "/preview_image/badoinkvr/3.jpg",
                  url + "/preview_image/badoinkvr/4.jpg",
                  url + "/preview_image/badoinkvr/5.jpg",
                  url + "/preview_image/badoinkvr/6.jpg",
                  url + "/preview_image/badoinkvr/7.jpg",
                  url + "/preview_image/badoinkvr/8.jpg",
                  url + "/preview_image/badoinkvr/9.jpg",
                  url + "/preview_image/badoinkvr/10.jpg",
                  url + "/preview_image/badoinkvr/11.jpg"
              ],
              videos: [
                  {
                      type: "mp4",
                      poster: "http://p.badoinkvr.com/Posters/1.jpg",
                      video: "http://promo.badoinkvr.com/d/1_Tease%20Me%20Timea/BaDoinkVR_Tease_Me_Timea_trailer_streaming_high_180x180_3dh_LR.mp4"
                  }, {
                      type: "mp4",
                      poster: "http://p.badoinkvr.com/Posters/4.jpg",
                      video: "http://promo.badoinkvr.com/d/4_Maxing%20Out%20Kitana%20Lure/BaDoinkVR_Maxing_Out_Kitana_Lure_trailer_streaming_high_180x180_3dh_LR.mp4"
                  },
                  {
                      type: "mp4",
                      poster: "http://p.badoinkvr.com/Posters/6.jpg",
                      video: "http://promo.badoinkvr.com/d/6_Inside%20Blanches%20Bedroom/BaDoinkVR_Inside_Blanches_Bedroom_trailer_streaming_high_180x180_3dh_LR.mp4"
                  }, {
                      type: "mp4",
                      poster: "http://p.badoinkvr.com/Posters/8.jpg",
                      video: "http://promo.badoinkvr.com/d/8_180%20Degrees%20of%20Double%20D/BaDoinkVR_180_Degrees_of_Double_D_trailer_streaming_high_180x180_3dh_LR.mp4"
                  }
              ]
          },
          {
              url: "vr-bangers",
              name: "VR Bangers",
              link: "http://refer.ccbill.com/cgi-bin/clicks.cgi?CA=948167-0000&PA=2560999&BAN=0",
              main_image: url + "/aff_images/vr_bangers/1.jpg",
              image: [
                  url + "/aff_images/vr_bangers/1.jpg",
                  url + "/aff_images/vr_bangers/2.jpg",
                  url + "/aff_images/vr_bangers/3.jpg",
                  url + "/aff_images/vr_bangers/4.jpg",
                  url + "/aff_images/vr_bangers/5.jpg"
              ],
              text: [
                  "VRBangers is a great new studio focusing on VR Sex. They offer about 15 movies with great " +
                  "starlets, weekly growing. While many movies are just 360, their more recent movies have it " +
                  "all - 360 degree immersiveness an a eye-popping 3D quality."],
              tags: ["Straight", "Teen", "Asian"],
              image_preview: [
                  url + "/preview_image/vr_bangers/0.jpg",
                  url + "/preview_image/vr_bangers/00.jpg",
                  url + "/preview_image/vr_bangers/1.jpg",
                  url + "/preview_image/vr_bangers/2.jpg",
                  url + "/preview_image/vr_bangers/3.jpg",
                  url + "/preview_image/vr_bangers/4.jpg",
                  url + "/preview_image/vr_bangers/5.jpg",
                  url + "/preview_image/vr_bangers/6.jpg",
                  url + "/preview_image/vr_bangers/7.jpg",
                  url + "/preview_image/vr_bangers/8.jpg"
              ],
              videos: [
                  {
                      type: "mp4",
                      poster: "",
                      video: url + "/preview_video/vr_bangers/00.mp4"
                  },
                  {
                      type: "mp4",
                      poster: "",
                      video: url + "/preview_video/vr_bangers/0.mp4"
                  },
                  {
                      type: "mp4",
                      poster: "",
                      video: url + "/preview_video/vr_bangers/2.mp4"
                  }, {
                      type: "mp4",
                      poster: "",
                      video: url + "/preview_video/vr_bangers/4.mp4"
                  },
                  {
                      type: "mp4",
                      poster: "",
                      video: url + "/preview_video/vr_bangers/1.mp4"
                  }, {
                      type: "mp4",
                      poster: "",
                      video: url + "/preview_video/vr_bangers/3.mp4"
                  }
              ]
          },
          {
              url: "wankz-vr",
              name: "Wankz VR",
              link: "https://www.wankzvr.com/tiny/CvfgX",
              main_image: url + "/aff_images/wankvr/1.jpg",
              image: [
                  url + "/aff_images/wankvr/1.jpg",
                  url + "/aff_images/wankvr/2.gif"
              ],
              text: [
                  "Streaming Interactive Porn. Virtual Reality Sex is better than a Game, WankzVR.com streams " +
                  "3D VR PORN to your smart phone. Head tracking &amp; mobile ready. Get ready to put " +
                  "yourself inside our exclusive models and their videos. Our porn vr works with Oculus, " +
                  "Gear VR, any smart phone, and many other devices!"],
              tags: ["Straight", "Teen"],
              videos: [
                  {
                      type: "mp4",
                      poster: "https://cdns-i.wankzvr.com/2/2195/2195917/hero/large.jpg",
                      video: url + "/preview_video/wankzvr/1.mp4"
                  }, {
                      type: "mp4",
                      poster: "https://cdns-i.wankzvr.com/2/2195/2195911/cover/large.jpg",
                      video: url + "/preview_video/wankzvr/2.mp4"
                  },
                  {
                      type: "mp4",
                      poster: "https://cdns-i.wankzvr.com/2/2187/2187709/cover/large.jpg",
                      video: url + "/preview_video/wankzvr/3.mp4"
                  }, {
                      type: "mp4",
                      poster: "https://cdns-i.wankzvr.com/2/2261/2261919/hero/large.jpg",
                      video: url + "/preview_video/wankzvr/4.mp4"
                  }
              ]
          },
          {
              url: "dorcel-vr",
              name: "Dorcel VR",
              link: "http://www.dorcelvision.com/en/movies/marc-dorcel/elles-n-attendent-que-vous-dans-la-peau-d-un-acteur-x?aff=3839",
              main_image: url + "/aff_images/dorcel/1.jpg",
              image: [
                  url + "/aff_images/dorcel/1.jpg",
                  url + "/aff_images/dorcel/2.jpg"
              ],
              text: [
                  "Live a unique experience. And if for once in your life, YOU could have sex with the hottest" +
                  " pornstars in the world? This is exactly what Marc Dorcel offers you through this 360° 3D porn" +
                  " video in which YOU are the main performer."],
              tags: ["Straight", "Teen"],
              image_preview: [
                  url + "/preview_image/dorcel_vr/1.jpg",
                  url + "/preview_image/dorcel_vr/2.jpg",
                  url + "/preview_image/dorcel_vr/3.jpg",
                  url + "/preview_image/dorcel_vr/4.jpg",
                  url + "/preview_image/dorcel_vr/5.jpg",
                  url + "/preview_image/dorcel_vr/6.jpg"
              ]
          },
          {
              url: "tmwvrnet",
              name: "Teen Mega World",
              link: "http://tmwvrnet.com/jump.php?account=17688&design=001&programm=onlysign",
              main_image: url + "/aff_images/tmwvrnet/1.jpg",
              image: [],
              text: [
                  "Teen Mega World- present exclusive videos with hottest stunning girls.",
                  "Teen Mega World present exclusive videos with hottest stunning girls. All videos are in 3d quality " +
                  "and binaural sound. Wow, believe me, it is sound great. Also you can rate your lovely girl model or " +
                  "scene, that is such a good chance to see them again. Payment: billing in one payment for 12 month will get you 75% savings!"],
              tags: ["Straight", "Teen"],
              image_preview: [
                  url + "/preview_image/tmwvrnet/0000.jpg",
                  url + "/preview_image/tmwvrnet/00000.jpg",
                  url + "/preview_image/tmwvrnet/0.jpg",
                  url + "/preview_image/tmwvrnet/000.jpg",
                  url + "/preview_image/tmwvrnet/01.jpg",
                  url + "/preview_image/tmwvrnet/001.jpg",
                  url + "/preview_image/tmwvrnet/110.jpg",
                  url + "/preview_image/tmwvrnet/1.jpg",
                  url + "/preview_image/tmwvrnet/2.jpg",
                  url + "/preview_image/tmwvrnet/3.jpg",
                  url + "/preview_image/tmwvrnet/4.jpg",
                  url + "/preview_image/tmwvrnet/5.jpg",
                  url + "/preview_image/tmwvrnet/6.jpg",
                  url + "/preview_image/tmwvrnet/7.jpg",
                  url + "/preview_image/tmwvrnet/8.jpg",
                  url + "/preview_image/tmwvrnet/9.jpg",
                  url + "/preview_image/tmwvrnet/10.jpg",
                  url + "/preview_image/tmwvrnet/11.jpg",
                  url + "/preview_image/tmwvrnet/12.jpg",
                  url + "/preview_image/tmwvrnet/13.jpg",
                  url + "/preview_image/tmwvrnet/14.jpg",
                  url + "/preview_image/tmwvrnet/15.jpg",
                  url + "/preview_image/tmwvrnet/16.jpg",
                  url + "/preview_image/tmwvrnet/17.jpg"
              ]
          },
          {
              url: "virtual-real-porn",
              name: "Virtual Real Porn",
              link: "http://hits.epochstats.com/hits.php?clc=297f0bbc764d6dc4361483ef702b16bf&id=16485584",
              main_image: url + "/aff_images/virtual_real_porn/1.jpg",
              image: [url + "/aff_images/virtual_real_porn/1.jpg"],
              text: [
                  "The first player in the VR Porn industry and still the best. With a total of over " +
                  "100 VR Porn Movies, from straight to lesbian, gay to bisexual. Virtual Real Porn offers" +
                  " VR Sex Movies in incredibly good quality - their VR Sex Videos are State of the Art.",
                  "Your neighbors are hot. No, your neighbors are the hottest chicks in the city. And today " +
                  "they come to your house to fuck you. It is not the best script ever written, but you will " +
                  "love it. Now, take a deep breath before stuff your hard cock deep inside the pussies of " +
                  "Jasmine Webb, Gina Gerson and Kiara Lord until you shoot your hot load in their mouths." +
                  " Turn on your headset and enjoy this interracial orgy in 180º FOV and our awesome Binaural" +
                  " Sound in your Smartphone Cardboard, Samsung Gear VR or Oculus Rift!"],
              tags: ["Straight", "Teen", "Anal"]
          },
          // {
          //     url: 'metaverse-xxx',
          //     name: 'MetaverseXXX',
          //     link: 'https://metaversexxx.com/ref/28/',
          //     // main_image: url + '/aff_images/virtual_real_porn_gay/1.jpg',
          //     // image: [url + '/aff_images/virtual_real_porn_gay/1.jpg'],
          //     text: ['Feel the real Gay sex like you were there! VR Gay Porn for Oculus Rift, ' +
          //     'Samsung Gear VR, Google Cardboard and all the VR headsets. Special Offer 2,95€',
          //         'When you use the social networks to hook up, you can be lucky or not. Or… ' +
          //         'you can meet the hottest boy you’ve ever seen and drill his cute ass, let him suck your' +
          //         ' dick and spread your hot load in his pretty face. That’s the case of Logan Moore, ' +
          //         'who happens to have a match with the cute blonde Darius Ferdynand. Indeed, the best ' +
          //         'use of an app ever. Now, get your VR headset to enjoy this VR porn scene in 180º ' +
          //         'FOV and our awesome Binaural Sound in your Smartphone Cardboard, Samsung Gear VR, ' +
          //         'Oculus Rift & HTC Vive!']
          // },
          // {
          //     name: 'Virtual Real Porn (Trans)',
          //     link: 'http://hits.epochstats.com/hits.php?clc=9792e29409173bf1477c2708a34669ab&id=16485584',
          //     image: []
          // },
          {
              url: "czech-vr",
              name: "Czech VR",
              link: "http://join.czechvr.com/track/MjY4LjEuMTAuMTEuMC41OC4wLjAuMA",
              main_image: url + "/aff_images/pornomental/1.jpg",
              image: [url + "/aff_images/pornomental/1.jpg"],
              previev_videos: [
                  "http://join.czechvr.com/gallery/MjY4LjUuMTAuMTEuMC41NC4wLjAuMA",
                  "http://join.czechvr.com/gallery/MjY4LjUuMTAuMTEuMC41MS4wLjAuMA",
                  "http://join.czechvr.com/gallery/MjY4LjUuMTAuMTEuMC40Ni4wLjAuMA",
                  "http://join.czechvr.com/gallery/MjY4LjUuMTAuMTEuMC40Mi4wLjAuMA",
                  "http://join.czechvr.com/gallery/MjY4LjUuMTAuMTEuMC40MC4wLjAuMA",
                  "http://join.czechvr.com/gallery/MjY4LjUuMTAuMTEuMC4zNC4wLjAuMA"
              ],
              text: [
                  "A new VR Porn Studio from the czech republic with sexy sluts from eastern " +
                  "Europe. See these hot sluts fulfill all the sexual dreams you ever had. As everybody " +
                  "knows - czech girls are super pretty and naturally nasty :)"],
              tags: ["Straight", "Teen", "Lesbian"],
              videos: [
                  {
                      type: "mp4",
                      poster: "http://pornomental.net/czechvr/videos/img/016-czechvr-3d-1200x600-60fps-stream-trailer-1.jpg",
                      video: "http://pornomental.net/czechvr/videos/download/016/016-czechvr-3d-2880x1440-60fps-oculusrift-trailer-1.mp4"
                  },
                  {
                      type: "mp4",
                      poster: "http://pornomental.net/czechvr/videos/img/031-czechvr-3d-1200x600-60fps-stream-trailer-1.jpg",
                      video: "http://pornomental.net/czechvr/videos/download/031/031-czechvr-3d-2880x1440-60fps-oculusrift-trailer-1.mp4"
                  },
                  {
                      type: "mp4",
                      poster: "http://pornomental.net/czechvr/videos/img/044-czechvr-3d-1200x600-60fps-stream-trailer-1.jpg",
                      video: "http://pornomental.net/czechvr/videos/download/044/044-czechvr-3d-2880x1440-60fps-oculusrift-trailer-1.mp4"
                  }, {
                      type: "mp4",
                      poster: "http://pornomental.net/czechvr/videos/img/046-czechvr-3d-1200x600-60fps-stream-trailer-1.jpg",
                      video: "http://pornomental.net/czechvr/videos/download/046/046-czechvr-3d-3840x1920-60fps-oculusrift-trailer-1.mp4"
                  }
              ]
          },
          {
              url: "holo-girls",
              name: "Holo Girls",
              link: "http://clickflow.net/?a=247&oc=8&c=7&p=r&s1=",
              main_image: url + "/aff_images/holo_girls/4.jpg",
              image: [
                  url + "/aff_images/holo_girls/1.jpg",
                  url + "/aff_images/holo_girls/2.jpg",
                  url + "/aff_images/holo_girls/3.jpg",
                  url + "/aff_images/holo_girls/4.jpg"

              ],
              text: [
                  "Hologirls VR offers about 12 movies in great quality at the moment: 270 to 360 degree, " +
                  "all in HD with great 3D quality with hot straight, lesbian and orgy scenes. Check it out!"],
              tags: ["Straight", "Teen"]
          },
          {
              url: "reality-lovers",
              name: "Reality Lovers",
              link: "http://1mobilecash.net/?affId=11166719569&affiliateAd=11167401198&niche=vr",
              main_image: url + "/aff_images/reality_lovers/1.jpg",
              image: [
                  url + "/aff_images/reality_lovers/1.jpg"
              ],
              text: [
                  "Immerse yourself in the most realistic virtual reality porn videos. Watch on " +
                  "Google Cardboard, Oculus Rift and Samsung Gear VR. Check it out now!"],
              tags: ["Straight", "Teen"]
          },
          {
              url: "virtual-porn-desire",
              name: "Virtual Porn Desire",
              link: "http://refer.ccbill.com/cgi-bin/clicks.cgi?CA=948537-0000&PA=2561068&BAN=0",
              main_image: url + "/aff_images/virtaual_porn_desire/2.jpg",
              image: [
                  url + "/aff_images/virtaual_porn_desire/1.jpg",
                  url + "/aff_images/virtaual_porn_desire/2.jpg"
              ],
              text: [
                  "Download the best VR players for Oculus, Samsung Gear VR, Android or iPhone " +
                  "smartphones so you can enjoy all original Virtual Reality Porn scenes.",
                  "What better way to release the daily work pressure, than go for a traditional sports massage " +
                  "with a twist. Watch Lauro work his way towards Amirahs gorgeous pussy. She has no idea whats" +
                  " coming, but she is loving it! Next week, we will upload the second part of this " +
                  "scene, where these two lovebirds fuck like there is no tomorrow!"],
              tags: ["Straight", "Teen"]
          },
          {
              url: "naughty-america",
              name: "Naughty America",
              link: "http://natour.naughtyamerica.com/track/lustity.12.8.293.0.0.0.0.0",
              main_image: url + "/aff_images/naughty_america/4.jpg",
              image: [
                  url + "/aff_images/naughty_america/3.jpg",
                  url + "/aff_images/naughty_america/4.jpg",
                  // url + '/aff_images/naughty_america/5.jpg',
                  url + "/aff_images/naughty_america/6.jpg",
                  url + "/aff_images/naughty_america/7.jpg",
                  url + "/aff_images/naughty_america/8.jpg"
              ],
              text: [
                  "Naughty America brings you the best virtual sex fantasies online. Experience VR porn " +
                  "for yourself and watch your favorite sex videos now!"],
              tags: ["Straight", "Teen"]
          },
          {
              url: "alice-x",
              name: "AliceX",
              link: "http://secure.cmvrclicks000.com/track/lustity.36.36.108.0.0.0.0.0.0.0.0",
              main_image: url + "/aff_images/alicex/1.jpg",
              // image: [
              //     url + '/aff_images/badoinkvr/23.jpg'
              // ],
              text: ["VR live cams for your phone. Chat with sexy girls right now. AliceX - Where magic happens. ❤"]
              // image_preview: [
              //     url + '/preview_image/badoinkvr/1.jpg'
              // ],
              // videos: [
              //     {
              //         type: 'mp4',
              //         poster: 'http://p.badoinkvr.com/Posters/1.jpg',
              //         video: 'http://promo.badoinkvr.com/d/1_Tease%20Me%20Timea/BaDoinkVR_Tease_Me_Timea_trailer_streaming_high_180x180_3dh_LR.mp4'
              //     }, {
              //         type: 'mp4',
              //         poster: 'http://p.badoinkvr.com/Posters/4.jpg',
              //         video: 'http://promo.badoinkvr.com/d/4_Maxing%20Out%20Kitana%20Lure/BaDoinkVR_Maxing_Out_Kitana_Lure_trailer_streaming_high_180x180_3dh_LR.mp4'
              //     }
              // ]
          },
          {
              url: "pornhub-vr",
              name: "PornHub VR",
              link: "http://www.pornhub.com?utm_source=paid&utm_medium=hubtraffic&utm_campaign=hubtraffic_lustity",
              main_image: url + "/aff_images/porn_hub/2.jpg",
              image: [
                  url + "/aff_images/porn_hub/1.jpg",
                  url + "/aff_images/porn_hub/2.jpg"
              ],
              text: [
                  "Free porn sex videos & pussy movies. Porn hub is the ultimate xxx porn,sex and pussy " +
                  "tube, download sex videos or stream free xxx and free sex movies."],
              tags: ["Straight", "Teen"]
          },
          {
              url: "virtual-real-porn-gay",
              name: "Virtual Real Porn (Gay)",
              link: "http://hits.epochstats.com/hits.php?clc=7183ae630461102bbc36a354265f629e&id=16485584",
              main_image: url + "/aff_images/virtual_real_porn_gay/1.jpg",
              image: [url + "/aff_images/virtual_real_porn_gay/1.jpg"],
              text: [
                  "Feel the real Gay sex like you were there! VR Gay Porn for Oculus Rift, " +
                  "Samsung Gear VR, Google Cardboard and all the VR headsets. Special Offer 2,95€",
                  "When you use the social networks to hook up, you can be lucky or not. Or… " +
                  "you can meet the hottest boy you’ve ever seen and drill his cute ass, let him suck your" +
                  " dick and spread your hot load in his pretty face. That’s the case of Logan Moore, " +
                  "who happens to have a match with the cute blonde Darius Ferdynand. Indeed, the best " +
                  "use of an app ever. Now, get your VR headset to enjoy this VR porn scene in 180º " +
                  "FOV and our awesome Binaural Sound in your Smartphone Cardboard, Samsung Gear VR, " +
                  "Oculus Rift & HTC Vive!"],
              tags: ["Gay", "Teen"]
          },
          {
              url: "virtual-porn-360",
              name: "Virtual Porn 360",
              link: "http://hits.epochstats.com/hits.php?clc=f4dd4d206b71d28737cb1784fb21beec&id=16485584",
              main_image: url + "/aff_images/virtual_porn_360/3.jpg",
              image: [
                  url + "/aff_images/virtual_porn_360/1.jpg",
                  url + "/aff_images/virtual_porn_360/2.jpg",
                  url + "/aff_images/virtual_porn_360/3.jpg",
                  url + "/aff_images/virtual_porn_360/4.jpg"
              ],
              text: [
                  "One of the early adopters of VR technology in full 360 degree. Compared to the other " +
                  "studios their library is smaller and more exclusive. Their movies are 360 degree" +
                  " but not 3D which is not as immersive as 360 VR in 3D."],
              tags: ["Straight", "Teen"]
          }
      ],
      devices: [
          {
              url: "badoink-vr",
              name: "BaDoink VR",
              link: "http://go.badoinkvr.com/go.php?t=22759&aid=108833&sid=06172016_300x250_BID8569",
              main_image: url + "/aff_images/badoinkvr/23.jpg",
              image: [
                  url + "/aff_images/badoinkvr/23.jpg"
              ],
              text: [
                  "100+ exclusive videos. Lesbian, MILF and more. Enjoy it with your Oculus or " +
                  "Samsung Gear VR. Free VR Goggles with every subscription."],
              tags: ["Straight", "Teen"],
              image_preview: [
                  url + "/preview_image/badoinkvr/1.jpg",
                  url + "/preview_image/badoinkvr/2.jpg",
                  url + "/preview_image/badoinkvr/3.jpg",
                  url + "/preview_image/badoinkvr/4.jpg",
                  url + "/preview_image/badoinkvr/5.jpg",
                  url + "/preview_image/badoinkvr/6.jpg",
                  url + "/preview_image/badoinkvr/7.jpg",
                  url + "/preview_image/badoinkvr/8.jpg",
                  url + "/preview_image/badoinkvr/9.jpg",
                  url + "/preview_image/badoinkvr/10.jpg",
                  url + "/preview_image/badoinkvr/11.jpg"
              ],
              videos: [
                  {
                      type: "mp4",
                      poster: "http://p.badoinkvr.com/Posters/1.jpg",
                      video: "http://promo.badoinkvr.com/d/1_Tease%20Me%20Timea/BaDoinkVR_Tease_Me_Timea_trailer_streaming_high_180x180_3dh_LR.mp4"
                  }, {
                      type: "mp4",
                      poster: "http://p.badoinkvr.com/Posters/4.jpg",
                      video: "http://promo.badoinkvr.com/d/4_Maxing%20Out%20Kitana%20Lure/BaDoinkVR_Maxing_Out_Kitana_Lure_trailer_streaming_high_180x180_3dh_LR.mp4"
                  },
                  {
                      type: "mp4",
                      poster: "http://p.badoinkvr.com/Posters/6.jpg",
                      video: "http://promo.badoinkvr.com/d/6_Inside%20Blanches%20Bedroom/BaDoinkVR_Inside_Blanches_Bedroom_trailer_streaming_high_180x180_3dh_LR.mp4"
                  }, {
                      type: "mp4",
                      poster: "http://p.badoinkvr.com/Posters/8.jpg",
                      video: "http://promo.badoinkvr.com/d/8_180%20Degrees%20of%20Double%20D/BaDoinkVR_180_Degrees_of_Double_D_trailer_streaming_high_180x180_3dh_LR.mp4"
                  }
              ]
          }
      ]

  };
export default data;
