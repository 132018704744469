import React from "react";

import Layout from "../components/layout";
import Header from "../components/Header";
import SitesList from "../components/SitesList";
import Footer from "../components/Footer";

const IndexPage = () => (
  <Layout>
    <section>
      <Header/>
      <SitesList/>
      <Footer/>
    </section>
  </Layout>
);

export default IndexPage;
